import { useNavigate } from "react-router-dom";

const MenuItem = ({ item, icon}) => {
    const navigate = useNavigate();
    return (
        <div className='sidebar-icon group' onClick={() => navigate(`/${item.id}`)}>
            {item.url ?
                <img className="sidebar-image min-h-full min-w-full object-cover" src={item.url} alt="MenuItem"/> :
                icon}
            <span className="sidebar-tooltip group-hover:scale-100">
                {item.name}
            </span>
        </div>
    )
}

export default MenuItem
