import PropTypes from 'prop-types'
import useDarkMode from '../hooks/useDarkMode'

import {
    FaMoon,
    FaSun,
} from 'react-icons/fa';


const Header = ({ title }) => {



    return (
        <header className='header px-3 border-b border-blue-500'>
            <h1 className="font-bold">{title}</h1>
            <DarkModeThemeIcon />
        </header>
    )
}

Header.defaultProps = {
    title: 'DuczNotes App'
}

Header.propTypes = {
    title: PropTypes.string,
}

const DarkModeThemeIcon = () => {
    const [darkTheme, setDarkTheme] = useDarkMode();
    const handleMode = () => setDarkTheme(!darkTheme);
    return (
        <span onClick={handleMode}>
            {darkTheme ? (
                <FaSun size='24' className='top-navigation-icon' />
            ) : (
                <FaMoon size='24' className='top-navigation-icon' />
            )}
        </span>
    )
}

export default Header
