import { useState } from 'react'

const AddTask = ({onAdd, list}) => {
    const [name, setName] = useState('')
    const [day, setDay] = useState('')

    const onSubmit = (e) => {
        e.preventDefault()

        if(!name) {
            alert('Please add a task');
            return;
        }
        const id = list.id
        onAdd({ name: name, day : day, list:id})

        
        
        setName('');
        setDay('');
    }

    return (
        <form className='add-form' onSubmit={onSubmit}>
            <div className='form-control items-center'>
                <label>Task</label>
                <input className="dark:bg-gray-800 rounded-xl" type='text' placeholder='Add task' value={name} onChange={(e) => 
                    setName(e.target.value)}></input>
            </div>
            <div className='form-control items-center'>
                <label>Time</label>
                <input className="dark:bg-gray-800 rounded-xl" type='text' placeholder='Add day and time' value={day} onChange={(e) => 
                    setDay(e.target.value)}></input>
            </div>

            <input type='submit' value='Save Task' className=' bg-blue-600 dark:bg-black btn btn-block'/>

        </form>
    )
}

export default AddTask
