import TaskView from './TaskView'
import AddTask from './AddTask'

import { BsFillPlusCircleFill, BsFillXCircleFill } from "react-icons/bs"
import { AiFillSetting } from "react-icons/ai"
import {BsFillTrashFill} from "react-icons/bs"

import firebase from 'firebase/compat/app';
import { firestore, storage } from '../App';
import { setDoc, doc, query, collection, where, deleteDoc, updateDoc } from "firebase/firestore"
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteObject, ref } from 'firebase/storage'


const ListView = ({ list }) => {
    const [showAddTask, setAddTask] = useState(false);
    const navigate = useNavigate();
    const listDocRef = doc(firestore, "List", list.id)
    const q = query(collection(firestore, "Task"), where("list", "==", listDocRef))

    var [tasks] = useCollectionData(q)
    tasks = tasks && tasks.sort((a, b) => a.createdAt?.seconds - b.createdAt?.seconds)

    tasks = tasks && tasks.sort((a,b)  => {return (a===b) ? 0 : a.done? 1 :-1})

    const toggledDone = async (taskId) => {
        const taskDocRef = doc(firestore, "Task", taskId)
        await updateDoc(taskDocRef, {
            done: !tasks.find(task => task.id === taskId).done
        })
    }

    const deleteTask = async (taskId) => {
        await deleteDoc(doc(firestore, "Task", taskId))
    }

    const addTask = async (task) => {
        const newTaskDocRef = doc(collection(firestore, "Task"))

        await setDoc(
            newTaskDocRef,
            {
                name: task.name,
                day: task.day,
                done: false,
                list: listDocRef,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                uid: firebase.auth().currentUser.uid,
                id: newTaskDocRef.id
            })
    }

    const deleteList = async ()=>{
        if(list.url !== null){
            var oldimageRef = ref(storage, list.imageUrl)
            deleteObject(oldimageRef);
        }
        navigate('/')
        await deleteDoc((doc(firestore, "List", list.id)))
    }

    const editTask = async (id, newTask) => {
        const taskDocRef = doc(firestore, "Task", id);

        await updateDoc(taskDocRef, newTask)
    }

    return (
        <div className="taskParent h-full">
            <div className="listheader flex flex-row justify-between items-center">
                <h2 className="underline">{list && list.name}</h2>
                <div className="list-edit flex flex-row">
                    
                    <AiFillSetting size='24' onClick={()=>navigate(`/editList/${list.id}`)} className='cursor-pointer hover:text-green-500 transition-colors duration-300 ease-linear' />
                    <BsFillTrashFill size="24" onClick={deleteList} className='ml-2 cursor-pointer hover:text-red-800 transition-colors duration-300 ease-linear' />
                </div>
            </div>

            {tasks && tasks.length > 0 ? "" : <p className="italic opacity-75">No Tasks yet.</p>}
                <div className="tasksContainer">
                    {tasks && tasks.map(task => <TaskView key={`task-${task.id}-${list.id}"`} task={task}
                        onToggle={toggledDone} onDelete={deleteTask} onEdit={editTask} />)}
                </div>

            <div className={`addTaskButton-wrapper left-16 bottom-0 fixed flex flex-col justify-center items-center w-full min-w-full`}>
                {showAddTask ? <BsFillXCircleFill className="addTaskButton-icon" onClick={() => setAddTask(false)} /> :
                    <BsFillPlusCircleFill className="addTaskButton-icon" onClick={() => setAddTask(true)} />}
                <div className={`flex justify-center w-full dark:bg-gray-900 bg-gray-400 opacity-80 ${showAddTask ? " h-56" : "h-0"} transition-height duration-300 ease-linear`}>
                    <AddTask list={list} onAdd={addTask} />
                </div>
            </div>




        </div>
    )
}

export default ListView
