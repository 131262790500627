import { FaTimes, FaRegEdit, FaCheck } from 'react-icons/fa'
import { useState } from 'react'



const TaskView = ({ task, onDelete, onToggle, onEdit }) => {
    const [name, setName] = useState(task.name)
    const [day, setDay] = useState(task.day)
    const [editable, setEditable] = useState(false)

    const Edit = () => {
        setEditable(true)
    }

    const SubmitEdit = id => {
        setEditable(false);
        const updatedTask = task
        updatedTask.name = name;
        updatedTask.day = day;
        onEdit(id, updatedTask)
    }
    return (
        <div className={`task border-blue-500 border-b-2 bg-gray-300 dark:bg-gray-700 dark:text-white ${task.done ? 'done' : ''}`}>
            <div className='task-check'>
                <FaCheck className='text-green-700 bg-blue-200 p-2 rounded-3xl text-4xl hover:bg-green-700 hover:text-white transition-all duration-300 ease-linear' onClick={()=>onToggle(task.id)}></FaCheck>
            </div>
            <div className='task-content'>

                <div className='taskText'>
                    <input className='bg-gray-700' type="text" maxLength={256} value={name} onChange={e => setName(e.target.value)} disabled={!editable}></input>
                    <FaTimes style={{ color: 'red', cursor: 'pointer' }} onClick={() => onDelete(task.id)} />
                </div>

                <div className="taskDescription">
                    <input className='text-xs bg-gray-700' type="text" value={day} onChange={e => setDay(e.target.value)} disabled={!editable}></input>
                    {editable ? <FaCheck style={{ color: 'green' }} onClick={() => SubmitEdit(task.id)}></FaCheck> : <FaRegEdit style={{ color: 'black', cursor: 'pointer' }} onClick={() => Edit()} />}
                </div>
                            
            </div>
        </div>
    )
}

export default TaskView
