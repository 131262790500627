import { useState } from "react";

import { storage } from "../App";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { v4 } from "uuid";
import { AiFillCamera } from "react-icons/ai";

const AddList = ({ onAdd }) => {
  const [name, setName] = useState("");

  const [imageUpload, setImageUpload] = useState(null);

  const uploadImage = (e) => {
    e.preventDefault();
    if (!name) return;

    if (imageUpload == null) {
      onAdd({name,url:null, imageName:null})
    } else {


      const imageName = `images/${imageUpload.name + v4()}`
      const imageRef = ref(storage, imageName);
      uploadBytes(imageRef, imageUpload).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => onAdd({ name, url, imageName }));
      });
    }
  };

  return (
    <div className="addList p-5">
      <h1 className="underline">Add a new List</h1>
      <form className="add-list-form" onSubmit={uploadImage}>
        <div className="list-form-control">
          <div className="list-form-content flex flex-row justify-between items-center">
            <input
              className="text-black max-h-10 rounded-xl p-2"
              type="text"
              placeholder="New List"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></input>
            <div className="list-form-image cursor-pointer border-blue-400 border-2 h-60 w-60 relative rounded-full overflow-hidden">
              {imageUpload ? (
                <img
                  className="w-full h-full object-cover"
                  id="blah"
                  src={URL.createObjectURL(imageUpload)}
                  alt=""
                />
              ) : (
                ""
              )}

              <label htmlFor="image-input-tag" className="list-image-tag">
                <AiFillCamera
                  size="50"
                  className={`hover:scale-125 cursor-pointer transition-all duration-300 ease-linear ${imageUpload ? "opacity-0" : ""}`}
                />
                <input
                  className=""
                  id="image-input-tag"
                  type="file"
                  accept=".png,.jpg"
                  onChange={(e) => setImageUpload(e.target.files[0])}
                ></input>
              </label>
            </div>
          </div>

          <input
            type="submit"
            value="Add new List"
            className="btn dark:bg-black hover:bg-green-400 dark:hover:bg-green-400 transition-all duration-300 ease-linear"
          />
        </div>
      </form>
    </div>
  );
};

export default AddList;
