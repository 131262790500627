import { collection, doc, query, updateDoc, where, arrayUnion, arrayRemove } from 'firebase/firestore';
import { useEffect, useState } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { firestore, storage, auth } from '../App';
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { v4 } from "uuid";
import { AiFillCamera } from "react-icons/ai";
import {MdOutlineContentCopy} from "react-icons/all";
import { deleteObject } from 'firebase/storage';
import {ImCross} from "react-icons/all";

const EditList = () => {
    const { id } = useParams();
    const [list, SetList] = useState(null)
    const listDocRef = doc(firestore, "List", id)
    const q = query(collection(firestore, "List"), where("id", "==", id))
    var [lists] = useCollectionData(q, { idField: 'id' })

    const navigate = useNavigate();

    useEffect(() => {
        if (lists && lists.length === 1) {
            SetList(lists[0])
        }
    }, [lists])

    const updateList = async (data) => {
        if(auth.currentUser.uid !== list.uid){
            alert("You are not the owner of this list")
            return;
        }

        navigate(`/${id}`)
        await updateDoc(listDocRef, data)
        
    }

    const addMemberToList = async (member) =>{
        if(auth.currentUser.uid !== list.uid){
            alert("You are not the owner of this list")
            return;
        }

        try{
            await updateDoc(listDocRef, {
                members : arrayUnion(member)
            })
        }catch (error){
            console.log("Error while adding member: " + error)
        }
        alert("Member successfully added")

    }

    const removeMember = async (member) =>{
        try{
            await updateDoc(listDocRef,{
                members : arrayRemove(member)
            })
        }catch(error){
            console.log("Error while removing Member: " + error )
        }
    }

    return (
        <div>
            {list ? <EditListView list={list} onEdit={updateList} onAddMember={addMemberToList} onRemoveMember={removeMember} /> : "Loading.."}
        </div>
    )
}

export default EditList

const EditListView = ({ list, onEdit, onAddMember, onRemoveMember }) => {
    const [name, SetName] = useState(list.name)
    const [imageUpload, setImageUpload] = useState(null);


    const uploadNewImage = (e) => {
        if(auth.currentUser.uid !== list.uid){
            alert("You are not the owner of this list")
            return;
        }

        e.preventDefault();
        var formdata = {}
        if (name != list.name)
            if (name.length > 0) formdata['name'] = name;

        if (imageUpload != null) {
            formdata['image'] = imageUpload;
            if (list.imageUrl !== null) {
                var oldimageRef = ref(storage, list.imageUrl)
                deleteObject(oldimageRef);
            }
        }


        if (Object.keys(formdata) <= 0) return;
        if ('image' in formdata) {
            const imageName = `images/${formdata['image'].name + v4()}`
            formdata['imageUrl'] = imageName
            const imageRef = ref(storage, imageName);
            uploadBytes(imageRef, imageUpload).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    formdata['url'] = url
                    delete formdata.image;
                    console.log(typeof(url))
                    onEdit(formdata)
                });
                
            });

            
        }else{
            onEdit(formdata)
        }
        
    };
    return (
        <div className="edit-view">
            <form onSubmit={uploadNewImage}>


                <input className='dark:bg-gray-700 bg-gray-300' type="text" maxLength={256} value={name} onChange={e => SetName(e.target.value)}></input>


                <div className="list-form-image cursor-pointer border-blue-400 border-2 h-60 w-60 relative rounded-full overflow-hidden">
                    {imageUpload ? (
                        <img
                            className="w-full h-full object-cover"
                            id="blah"
                            src={URL.createObjectURL(imageUpload)}
                            alt="List Image"
                        />
                    ) : (
                        list.url ? (
                            <img
                                className="w-full h-full object-cover"
                                id="blah"
                                src={list.url}
                                alt="List Image"
                            />
                        ) : (
                            ""
                        )
                    )}

                    <label htmlFor="image-input-tag" className="list-image-tag">
                        <AiFillCamera
                            size="50"
                            className={`hover:scale-125 cursor-pointer transition-all duration-300 ease-linear ${imageUpload || list.url ? "opacity-0" : ""}`}
                        />
                        <input
                            className=""
                            id="image-input-tag"
                            type="file"
                            accept=".png,.jpg"
                            onChange={(e) => setImageUpload(e.target.files[0])}
                        ></input>
                    </label>
                </div>
                <input type="submit" className="btn dark:bg-gray-600" value="Save" />
            </form>
            <AddNewMember onAddMember={onAddMember}/>
            <ListMembers list={list} onRemove={onRemoveMember}/>
        </div>
    )
}

const AddNewMember = ({onAddMember})=>{
    const [member, setMember] = useState("")

    const addMember = ()=>{

        onAddMember(member)
    }

    return(
            <div>
                <div>Add User to list</div>
                <div className="text-gray-500 italic flex flex-row justify-start items-center">
                    <div className="mr-2">Your UID is: {auth.currentUser.uid}</div>
                    <MdOutlineContentCopy className="hover:text-blue-500 cursor-pointer" onClick={()=>navigator.clipboard.writeText(auth.currentUser.uid)}/>
                </div>

                <input type="text" placeholder="Friend UID" onChange={(e)=>setMember(e.target.value)} value={member} className="rounded-xl p-2 text-black"/>
                <button onClick={addMember} className="btn bg-blue-500">Add User</button>
            </div>

    )
}

const ListMembers = ({list, onRemove}) =>{

    return (
            <div>
                <div className="underline" >Members: </div>
                <div className="flex flex-col">
                    {list && list.members.map(member=> <ListMember key={`member-${member}`} list={list} member={member} onRemove={onRemove}/>)}
                </div>
            </div>
    )
}

const ListMember = ({member, list, onRemove}) => {
    const q = query(
            collection(firestore, "User"),
            where("uid", "==", member)
    )

    var [users] = useCollectionData(q)

    const removeMember = () =>{
        onRemove(member)
    }

    return (
            <div className="member flex-row items-center justify-start flex">
                {users && users[0].Username}
                {auth.currentUser.uid === list.uid && list.uid !==member ? <ImCross className="ml-2 cursor-pointer hover:scale-125" onClick={removeMember} color={'red'} /> : ""}
            </div>
    )
}