import MenuItem from "./MenuItem"
import { FaFire} from 'react-icons/fa'
import { BsPlusCircleFill } from 'react-icons/bs'
import { useNavigate } from "react-router-dom";

const Menu = ({ items, signout }) => {
    const navigate = useNavigate();
    return (
        <div className='fixed justify-between border-r border-blue-500 top-0 left-0 py-1 h-screen w-16 m-0 flex flex-col dark:bg-gray-900 bg-gray-100 text-white shadow-lg '>
            <div className="listitems">
                {items && items.map((item) => (
                    <div key={item.id} >
                        <MenuItem item={item} icon={<FaFire size="28" />} text={item.Name} />
                    </div>

                ))}
                    <div className="sidebar-icon" onClick={() => navigate('/newList')}>
                        <BsPlusCircleFill size="28" />
                    </div>

            </div>




            {signout}
        </div>

    )
}

export default Menu
