import { useEffect, useState } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { setDoc, doc, query, collection, where } from "firebase/firestore";

import { FiLogOut } from "react-icons/fi";

import Header from "./components/Header";
import ListView from "./components/ListView";
import Menu from "./components/Menu";
import AddList from "./components/AddList";
import EditList from "./components/EditList";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCI8nl7SeEPs9y_37-5wtpHBD0E5O21FTw",
  authDomain: "duczbackend.firebaseapp.com",
  projectId: "duczbackend",
  storageBucket: "duczbackend.appspot.com",
  messagingSenderId: "276119482799",
  appId: "1:276119482799:web:93289e29e9758e3749ff5a",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = getStorage(app);

function App() {
  const [user] = useAuthState(auth);

  return (
    <div className="DuczNotes h-full">{user ? <Notes /> : <SignIn />}</div>
  );
}

export default App;

const Notes = () => {
  const [showAddTask, setShowAddTask] = useState(false);

  const q = query(
    collection(firestore, "List"),
    where("members", "array-contains", auth.currentUser.uid)
  );

  var [lists] = useCollectionData(q, { idField: "id" });
  lists =
    lists && lists.sort((a, b) => a.createdAt?.seconds - b.createdAt?.seconds);

  const addNewList = async (data) => {
    const listname = data.name;
    const image = data.url;
    const imageName = data.imageName;
    const newListDocRef = doc(collection(firestore, "List"));

    await setDoc(newListDocRef, {
      name: listname,
      url: image,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      members: [auth.currentUser.uid],
      uid: auth.currentUser.uid,
      id: newListDocRef.id,
      imageUrl: imageName,
    });
  };

  return (
    <div className="routerView pl-16 dark:bg-gray-800 bg-gray-400 dark:text-white text-black pt-5">
      <Header
        title={`${auth.currentUser.displayName.split(" ")[0]}'s Notes`}
        onChangeDarkMode={() => setShowAddTask(!showAddTask)}
        showAdd={showAddTask}
      />
      <Router>
        <div className="content dark:bg-gray-700 bg-gray-300 overflow-hidden p-5">
          <Routes>
            <Route
              key="Default"
              path="/"
              element={
                lists && lists.length > 0 ? (
                  <ListView list={lists[0]} />
                ) : (
                  <p>No existing lists</p>
                )
              }
            />
            {lists &&
              lists.map((list) => (
                <Route
                  key={list.id}
                  exact
                  path={`/${list.id}`}
                  element={<ListView list={list} />}
                />
              ))}
            <Route
              key="NewList"
              path="/newList"
              element={<AddList onAdd={addNewList} />}
            />
            <Route key="EditList" path="editList/:id" element={<EditList />} />
          </Routes>
        </div>
        <Menu items={lists} signout={<SignOut />} />
      </Router>
    </div>
  );
};

const SignIn = () => {
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  };

  const signInWithEmailAndPassword = () => {
    const provider = new firebase.auth.EmailAuthProvider();
    auth.signInWithEmailAndPassword(provider);
  };

  const jiggle = (e)=>{
    const mouseX = e.clientX;
    const mouseY = e.clientY;

    const descs = document.querySelectorAll('.description');
    descs.forEach((desc,index)=>{
      const factor = index%2===0?1:-1;
      desc.style.transform = `translate(${mouseX/100 * factor}px, ${mouseY/100*(factor*-1)}px)`;

    })
  }

  useEffect(() => {
    document.addEventListener('mousemove', jiggle);
    return () => {
      document.removeEventListener('mousemove', jiggle);
    }
  }, []);

  return (
    <div className="bg-gray-900 w-full signIn text-white flex flex-col items-center p-3 poly wave-blue-bottom relative">
      <div className="description-1  description top-[6rem] left-[6%] absolute ">
        A modern note taking app with a simple and clean UI. <br />
      </div>
      <div className="description-2  description top-[26rem] right-[10%] absolute ">
        Easy and fast to use. <br />
      </div>
      <div className="description-3 description top-[8rem] right-[2%]  absolute  ">
        Share your lists with others to cooperate <br />
      </div>
      <div className="description-4 description top-[30rem] left-[4%]  absolute">
        Minimal Setup. Start within Seconds. <br />
      </div>
      <h1 className="underline text-3xl">Welcome to the DuczNotes App</h1>
      <div className="signInContent flex mt-52 items-start justify-center h-full ">
        <div className="signInBox mt-5 bg-blue-500 border-2 border-white rounded-xl p-3 w-full">
          <h2>Please Sign In</h2>
          <button
            className="bg-green-500 mt-5 p-1 rounded-xl hover:bg-white hover:text-black transition-colors duration-300 ease-linear"
            onClick={signInWithGoogle}
          >
            Sign in with Google
          </button>
        </div>
      </div>
    </div>
  );
};

const SignOut = () => {
  return (
    auth.currentUser && (
      <div className="sidebar-icon group" onClick={() => auth.signOut()}>
        <FiLogOut size="24" />
        <span className="sidebar-tooltip group-hover:scale-100">Sign Out</span>
      </div>
    )
  );
};
